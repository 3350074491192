import WelcomeUser from "../components/header/WelcomeUser";
import { KakeiboCards } from "../components/smartcard";
import { useGetExpenseByComponentQuery } from "../services/statisticsApi";
import { getCurrentMonth, getCurrentYear } from "../utility";
import { KakeiboTrackerChart, ComponentStatsChart } from "../data";
import { getThemeColor } from "../utility";
import { ComponentForm } from "../containers";

function Statistics() {
  const month = getCurrentMonth();
  const year = getCurrentYear();

  const { data: expenseStat, isFetching: expenseLoading } =
    useGetExpenseByComponentQuery({ year, month });

  return (
    <div className="overview-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <KakeiboCards />
      </div>
      <div className="component-graph-panel">
        <div className="data-viewport">
          <ComponentForm />
        </div>
      </div>
      <div className="visualizer-panel">
        <div className="graphs-panel">
          <ComponentStatsChart
            data={expenseStat?.result}
            isFetching={expenseLoading}
            heading="EXPENSE STATISTICS"
            height="40vh"
            width="35%"
            bkgColor={getThemeColor("--alpha-color")}
          />
          <KakeiboTrackerChart />
        </div>
      </div>
    </div>
  );
}

export default Statistics;
