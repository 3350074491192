import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GenericResponse } from "../interfaces";

const staticApi = createApi({
  reducerPath: "staticApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_FM_API_BASE_URL,
  }),

  endpoints: (builder) => ({
    getContributors: builder.query<string[], void>({
      query: () => ({
        url: "static/contributors",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<string[]>) =>
        response.result,
    }),

    getTimeIntervals: builder.query<string[], void>({
      query: () => ({
        url: "static/timeinterval",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<string[]>) =>
        response.result,
    }),
  }),
});

export const { useGetContributorsQuery, useGetTimeIntervalsQuery } = staticApi;

export default staticApi;
