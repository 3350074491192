import { GenericResponse } from "../interfaces";
import financeApi from "./financeApi";
import { ITableData } from "../interfaces";
import { Tags } from "../store/tags";

const configurationApi = financeApi.injectEndpoints({
  endpoints: (builder) => ({
    getConfigTables: builder.query<ITableData[], string>({
      query: (table) => ({
        url: `configuration/${table}`,
        method: "GET",
        params: {},
      }),
      providesTags: [Tags.ConfigView],
      transformResponse: (response: GenericResponse<ITableData[]>) =>
        response.result,
    }),

    addConfiguration: builder.mutation({
      query: ({ tableName: table, payload: configData }) => ({
        url: `configuration/${table}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: configData,
      }),
      invalidatesTags: [Tags.ConfigView],
    }),
  }),
});

export const { useGetConfigTablesQuery, useAddConfigurationMutation } =
  configurationApi;
export default configurationApi;
