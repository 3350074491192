import { ChangeEvent, useState } from "react";
import { ApiResponse, IReminderForm } from "../interfaces";
import {
  useAddReminderMutation,
  useGetRecurringElementsQuery,
  useGetReminderItemsQuery,
  useGetReminderTypesQuery,
} from "../services/remindersApi";
import { toastNotify } from "../utility";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputFormField, SelectFormField } from "../components/formcontrols";
import { Calendar } from "primereact/calendar";
import { DropdownChangeEvent } from "primereact/dropdown";
import {
  useGetContributorsQuery,
  useGetTimeIntervalsQuery,
} from "../services/staticApi";
import {
  useGetTransactionGroupsQuery,
  useGetTransactionTypesQuery,
} from "../services/transactionsApi";
import { MainLoader } from "../common";

function ReminderForm() {
  const [reminder, setReminder] = useState<IReminderForm>(() => {
    return {
      remindertype: "",
      elementtype: "",
      recurringelement: "",
      transactgroup: "",
      transacttype: "",
      ownername: "",
      begindate: new Date(),
      enddate: null,
      duedate: null,
      reminderdate: null,
      dayofmonth: "",
      ddmmofyear: "",
      snoozeinterval: "",
      snoozetime: "",
    } as IReminderForm;
  });

  const [elementId, setElementId] = useState("");
  const [elementType, setElementType] = useState("");
  const [loading, setLoading] = useState(false);

  const { data: reminderTypes } = useGetReminderTypesQuery();
  const { data: timeIntervals } = useGetTimeIntervalsQuery();
  const { data: transactTypes } = useGetTransactionTypesQuery();
  const { data: transactGroups } = useGetTransactionGroupsQuery();
  const { data: contributors } = useGetContributorsQuery();
  const { data: reminderElements } = useGetRecurringElementsQuery();
  const { data: reminderItems } = useGetReminderItemsQuery(elementId!, {
    skip: !elementId,
  });

  const dayofMonthOptions = Array.from({ length: 31 }, (_, i) => ({
    label: i + 1,
    value: (i + 1).toString(),
  }));

  const [addReminder] = useAddReminderMutation();

  const handleAddReminder = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(reminder);
    setLoading(true);
    try {
      if (reminder) {
        const response: ApiResponse = await addReminder(reminder);
        if (response.data) {
          toastNotify("Reminder added !");
        } else if (response.error) {
          toastNotify(response.error.data.errormessages[0], "error");
        }
      }
    } catch (error) {
      toastNotify("Reminder error", "error");
    }
    setLoading(false);
  };

  const handleSelectChange = (e: DropdownChangeEvent) => {
    const { name, value } = e.target;

    if (name === "elementType") {
      setElementId(value.id);
      setElementType(value);
    } else {
      setReminder((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setReminder((prev) => {
      return {
        ...prev,
        [name]: value,
      } as IReminderForm;
    });
  };

  return (
    <>
      {loading && (
        <div className="loader-wrapper">
          <MainLoader />
        </div>
      )}
      <form className="transact-form" onSubmit={handleAddReminder}>
        <h3>Add a Reminder</h3>
        <div>
          <ul>
            <li>
              <SelectFormField
                label="Element"
                name="elementType"
                value={elementType}
                options={reminderElements || []}
                optionLabel="name"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <SelectFormField
                label="Reminder Item"
                name="recurringelement"
                value={reminder?.recurringelement}
                options={reminderItems || []}
                optionLabel="recurringelement"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <SelectFormField
                label="Reminder Type"
                name="remindertype"
                value={reminder?.remindertype}
                options={reminderTypes || []}
                optionLabel="remindertype"
                onChange={handleSelectChange}
                required={false}
              />
            </li>
            <li>
              <SelectFormField
                label="Day of Month"
                name="dayofmonth"
                options={dayofMonthOptions || []}
                optionLabel="label"
                value={reminder?.dayofmonth}
                onChange={handleSelectChange}
                required={false}
              />
            </li>
            <li>
              <InputFormField
                label="Day of Year"
                name="ddmmofyear"
                value={reminder?.ddmmofyear}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <label>Reminder Date</label>
              <div className="card flex justify-content-center ">
                <Calendar
                  value={reminder?.reminderdate}
                  name="reminderdate"
                  onChange={handleSelectChange}
                  dateFormat="dd/mm/yy"
                  className="p-inputtext-sm"
                  showIcon
                />
              </div>
            </li>
            <li>
              <label>Due Date</label>
              <div className="card flex justify-content-center ">
                <Calendar
                  value={reminder?.duedate}
                  name="duedate"
                  onChange={handleSelectChange}
                  dateFormat="dd/mm/yy"
                  className="p-inputtext-sm"
                  showIcon
                />
              </div>
            </li>
          </ul>
          <ul>
            <li>
              <SelectFormField
                label="Type"
                name="transacttype"
                value={reminder?.transacttype}
                options={transactTypes || []}
                optionLabel="transacttype"
                onChange={handleSelectChange}
                required={false}
              />
            </li>
            <li>
              <SelectFormField
                label="Transaction Group"
                name="transactgroup"
                value={reminder?.transactgroup || ""}
                options={transactGroups || []}
                optionLabel="transactgroup"
                onChange={handleSelectChange}
                required={false}
              />
            </li>
            <li>
              <SelectFormField
                label="Contributor"
                name="ownername"
                value={reminder?.ownername}
                options={contributors || []}
                optionLabel="ownername"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <SelectFormField
                label="Snooze Interval"
                name="snoozeinterval"
                value={reminder?.snoozeinterval}
                options={timeIntervals || []}
                optionLabel="snoozeinterval"
                onChange={handleSelectChange}
                required={false}
              />
            </li>
            <li>
              <InputFormField
                label="Snooze Time"
                name="snoozetime"
                value={reminder?.snoozetime}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <label>Begin Date</label>
              <div className="card flex justify-content-center ">
                <Calendar
                  value={reminder?.begindate}
                  name="begindate"
                  onChange={handleSelectChange}
                  dateFormat="dd/mm/yy"
                  className="p-inputtext-sm"
                  showIcon
                />
              </div>
            </li>
            <li>
              <label>End Date</label>
              <div className="card flex justify-content-center ">
                <Calendar
                  value={reminder?.enddate}
                  name="enddate"
                  onChange={handleSelectChange}
                  dateFormat="dd/mm/yy"
                  className="p-inputtext-sm"
                  showIcon
                />
              </div>
            </li>
          </ul>
        </div>
        <button
          className="transact-form-button"
          type="submit"
          //onClick={() => handleAddReminder}
        >
          Add Reminder
        </button>
      </form>
    </>
  );
}

export default ReminderForm;
