import WelcomeUser from "../components/header/WelcomeUser";
import { useSelector } from "react-redux";
import { RootState } from "../store/redux";
import { SmartCard } from "../components/smartcard";
import { useGetRemindersQuery } from "../services/remindersApi";
import { RemindersTable } from "../data";
import { ReminderForm } from "../containers";
import "../styles/forms/selectbox.css";

function Reminders() {
  const financialData = useSelector(
    (state: RootState) => state.monthlyReportStore.financeOverview
  );

  const { data, isFetching } = useGetRemindersQuery();

  return (
    <div className="transact-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <SmartCard data={financialData} isLoading={false} />
      </div>
      <div className="transact-form-panel">
        <ReminderForm />
      </div>
      <RemindersTable data={data?.result} isFetching={isFetching} />
    </div>
  );
}

export default Reminders;
