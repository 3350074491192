import React from "react";
import { IReminder } from "../../interfaces";
import { MainLoader } from "../../common";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

interface RemindersTableProps {
  data: IReminder[] | undefined;
  isFetching: boolean;
}

const RemindersTable: React.FC<RemindersTableProps> = ({
  data,
  isFetching,
}) => {
  return (
    <>
      {isFetching && <MainLoader />}
      {!isFetching && (
        <div className="card">
          <DataTable
            value={data}
            header="REMINDERS"
            scrollable
            scrollHeight="50vh"
            rows={20}
            size="small"
          >
            <Column field="rmdid" header="ID"></Column>
            <Column field="remindertype" header="Type"></Column>
            <Column field="elementtype" header="Item"></Column>
            <Column field="recurringelement" header="Description"></Column>
            <Column field="ownername" header="Owner"></Column>
            <Column field="begindate" header="Begin Date"></Column>
            <Column field="reminderdate" header="Reminder Date"></Column>
            <Column field="snoozeinterval" header="Snooze Interval"></Column>
            <Column field="snoozetime" header="Snooze Time"></Column>
            <Column field="dayofmonth" header="Day of Month"></Column>
            <Column field="ddmmofyear" header="Day of Year"></Column>
            <Column field="enddate" header="End Date"></Column>
            <Column field="duedate" header="Due Date"></Column>
          </DataTable>
        </div>
      )}
    </>
  );
};

export default RemindersTable;
