import React from "react";
import { InputText } from "primereact/inputtext";

interface FormFieldProps {
  label: string;
  name: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
}

const InputFormField: React.FC<FormFieldProps> = ({
  label,
  name,
  value,
  onChange,
  type = "text",
  className = "p-inputtext-sm",
  placeholder = "",
  disabled = false,
  required = false,
}) => {
  return (
    <div>
      <label>{label}</label>
      <InputText
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
      />
    </div>
  );
};

export default InputFormField;
