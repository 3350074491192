import React, { useState } from "react";
import { DynamicForm } from "../containers";
import { tableSchema } from "../config/tableSchema";
import {
  useAddConfigurationMutation,
  useGetConfigTablesQuery,
} from "../services/configurationApi";
import WelcomeUser from "../components/header/WelcomeUser";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { toastNotify } from "../utility";
import { MainLoader } from "../common";

const Configuration: React.FC = () => {
  const [selectedTable, setSelectedTable] = useState<string | null>(null);
  const [addConfiguration] = useAddConfigurationMutation();

  const {
    data: tableData = [],
    isFetching,
    error,
  } = useGetConfigTablesQuery(selectedTable || "", { skip: !selectedTable });

  const handleTableSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value || null;
    setSelectedTable(value !== "" ? value : null);
  };

  const handleSubmit = async (record: Record<string, any>) => {
    if (!selectedTable) return;
    try {
      const response = await addConfiguration({
        tableName: selectedTable,
        payload: record,
      }).unwrap();

      if (response) {
        toastNotify("Config added successfully");
      }
    } catch (err) {
      const errorMessage =
        (err as { data: string }).data || "An unexpected error occurred";
      toastNotify(`Configuration Error: ${errorMessage}`, "error");
    }
  };

  return (
    <div className="overview-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="report-search-form">
        <h3>Configuration Table Editor</h3>
        <select onChange={handleTableSelect}>
          <option value="">Select a Table</option>
          {Object.keys(tableSchema).map((key) => (
            <option key={key} value={key}>
              {tableSchema[key].name}
            </option>
          ))}
        </select>
      </div>

      {/* Dynamic Form */}
      {selectedTable && (
        <div className="dynamic-form-panel">
          <DynamicForm
            schema={tableSchema[selectedTable]}
            onSubmit={handleSubmit}
          />
        </div>
      )}

      {/* Error Message */}
      {error && <p style={{ color: "red" }}>Error fetching data</p>}

      {/* Table Data */}
      {isFetching && <MainLoader />}
      {!isFetching && selectedTable && tableData.length > 0 && (
        <div className="report-data-panel">
          {tableData.length > 0 && (
            <div style={{ minWidth: "50%", width: "fit-content" }}>
              <DataTable
                header={tableSchema[selectedTable!].name}
                value={tableData}
                scrollable
                scrollHeight="53vh"
                paginator
                rows={24}
                rowsPerPageOptions={[15, 25, 50]}
                showGridlines
              >
                {Object.keys(tableData[0]).map((key) => (
                  <Column
                    key={key}
                    field={key}
                    header={key}
                    headerStyle={{ textTransform: "capitalize" }}
                  />
                ))}
                <Column
                  header="Edit"
                  style={{ width: "6%" }}
                  rowEditor={true}
                  headerStyle={{ minWidth: "5rem" }}
                  bodyStyle={{ textAlign: "center", color: "blue" }}
                ></Column>
              </DataTable>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Configuration;

/* 
<table border={1}>
              <thead>
                <tr>
                  {Object.keys(tableData[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, idx) => (
                      <td key={idx}>{String(value)}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
*/
