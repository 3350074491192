import React from "react";
import { SmartCardItemProps } from "../../interfaces";

const SmartCardItem: React.FC<SmartCardItemProps> = ({
  title,
  dataPoint,
  trend,
  trendStyle,
  trendIcon,
  iconClass,
  iconColor,
  iconStyle,
  width,
}) => {
  return (
    <div className="smartcard-frame" style={{ width: width }}>
      <div className="sc-top-section">
        <div className="sc-header">
          <span>{title}</span>
        </div>
        <div className="sc-datapoint">
          <span>{dataPoint}</span>
        </div>
        <div className="sc-icon">
          <div
            className={iconStyle}
            style={{ backgroundColor: iconColor, color: "#fff" }}
          >
            <i className={iconClass}></i>
          </div>
        </div>
      </div>
      <div className="sc-bottom-section">
        <span style={trendStyle}>
          <i className={trendIcon}></i>&nbsp;{trend}
        </span>
      </div>
    </div>
  );
};

export default SmartCardItem;
