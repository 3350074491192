import {
  GenericResponse,
  IElement,
  IReminder,
  IReminderForm,
  IReminderItem,
  IStaticData,
} from "../interfaces";
import financeApi from "./financeApi";
import { Tags } from "../store/tags";

const remindersApi = financeApi.injectEndpoints({
  endpoints: (builder) => ({
    getReminders: builder.query<GenericResponse<IReminder[]>, void>({
      query: () => ({
        url: "reminders",
        method: "GET",
        params: {},
      }),
      keepUnusedDataFor: 300,
      providesTags: [Tags.RemindersView],
    }),

    getReminderTypes: builder.query<string[], void>({
      query: () => ({
        url: "reminders/type",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<IStaticData[]>) =>
        response.result.map((array) => array.type),
    }),

    getRecurringElements: builder.query<IElement[], void>({
      query: () => ({
        url: "reminders/element",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<IElement[]>) =>
        response.result,
    }),

    getReminderItems: builder.query<string[], string | null>({
      query: (element) => ({
        url: "reminders/reminderitem",
        method: "GET",
        params: { elementid: element },
      }),
      transformResponse: (response: GenericResponse<IReminderItem[]>) =>
        response.result.map((array) => array.item),
    }),

    addReminder: builder.mutation({
      query: (reminderData: IReminderForm) => ({
        url: "reminders",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: reminderData,
      }),
      invalidatesTags: [Tags.RemindersView],
    }),
  }),
});

export const {
  useGetRemindersQuery,
  useGetReminderTypesQuery,
  useGetReminderItemsQuery,
  useGetRecurringElementsQuery,
  useAddReminderMutation,
} = remindersApi;

export default remindersApi;
