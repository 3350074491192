import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GenericResponse } from "../interfaces";

const componentsApi = createApi({
  reducerPath: "componentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_FM_API_BASE_URL,
  }),
  endpoints: (builder) => ({
    getComponents: builder.query<string[], void>({
      query: () => ({
        url: "components/names",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<string[]>) =>
        response.result,
    }),
  }),
});

export const { useGetComponentsQuery } = componentsApi;
export default componentsApi;
