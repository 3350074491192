import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "../src/styles/themes/global.css";
import "../src/styles/media/login.css";
import "../src/styles/media/dashboard.css";
import "../src/styles/media/form.css";
import "../src/styles/media/charts.css";
import { Provider } from "react-redux";
import store, { persistor } from "./store/redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PrimeReactProvider } from "primereact/api";
import { PersistGate } from "redux-persist/integration/react";
import { MainLoader, ThemeProvider } from "./common";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<MainLoader />} persistor={persistor}>
        <ThemeProvider>
          <ToastContainer />
          <PrimeReactProvider>
            <App />
          </PrimeReactProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

//ThemeProvider is inside PersistGate, so it's only active after persistence is ready.
