import React, { useEffect, useState } from "react";
import { ITableSchema } from "../interfaces";
import { InputFormField } from "../components/formcontrols";

interface DynamicFormProps {
  schema: ITableSchema;
  onSubmit: (data: Record<string, any>) => void;
  // Data is an object with field names as keys
}

const DynamicForm: React.FC<DynamicFormProps> = ({ schema, onSubmit }) => {
  const [formData, setFormData] = useState<Record<string, any>>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    const newValue =
      type === "number" ? (value === "" ? NaN : parseFloat(value)) : value;
    setFormData((prev) => ({ ...prev, [name]: newValue }));
    console.log(newValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(formData);
  };

  useEffect(() => {
    setFormData({});
  }, [schema]);

  return (
    <form className="transact-form" onSubmit={handleSubmit}>
      <h3>{schema.name}</h3>
      <div>
        <ul>
          {schema.fields.map((field) => (
            <li key={field.name}>
              <InputFormField
                label={field.label}
                type={field.type}
                name={field.name}
                value={
                  formData[field.name] === undefined ||
                  formData[field.name] === null
                    ? ""
                    : formData[field.name]
                }
                onChange={handleChange}
                required={field.required}
              />
            </li>
          ))}
        </ul>
      </div>
      <button className="transact-form-button" type="submit">
        Submit
      </button>
    </form>
  );
};

export default DynamicForm;
