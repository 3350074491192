import { ITableSchema } from "../interfaces";

export const tableSchema: Record<string, ITableSchema> = {
  group: {
    name: "Groups",
    fields: [{ name: "name", label: "Name", type: "text", required: true }],
  },
  person: {
    name: "People",
    fields: [
      { name: "name", label: "Name", type: "text", required: true },
      { name: "groupid", label: "Group ID", type: "number", required: true },
      { name: "isactive", label: "Is Active", type: "text", required: true },
    ],
  },
  accounttype: {
    name: "Account Types",
    fields: [{ name: "name", label: "Name", type: "text", required: true }],
  },
  transactmode: {
    name: "Transaction Modes",
    fields: [{ name: "name", label: "Name", type: "text", required: true }],
  },
  transacttype: {
    name: "Transaction Types",
    fields: [{ name: "name", label: "Name", type: "text", required: true }],
  },
  transactstate: {
    name: "Transaction States",
    fields: [
      { name: "type", label: "Type", type: "text", required: true },
      { name: "name", label: "Name", type: "text", required: true },
    ],
  },
  transactstatus: {
    name: "Transaction Status",
    fields: [{ name: "name", label: "Name", type: "text", required: true }],
  },
  transactgroup: {
    name: "Transaction Groups",
    fields: [
      { name: "name", label: "Name", type: "text", required: true },
      { name: "category", label: "Category", type: "text", required: true },
      {
        name: "trngrpid",
        label: "Trn Group ID",
        type: "text",
        required: true,
      },
      {
        name: "trngrptype",
        label: "Trn Group Type",
        type: "text",
        required: true,
      },
      {
        name: "transactstatusid",
        label: "Trn Status ID",
        type: "number",
        required: true,
      },
    ],
  },
  componentclassifier: {
    name: "Classifier",
    fields: [{ name: "name", label: "Name", type: "text", required: true }],
  },
  transactcomponent: {
    name: "Components",
    fields: [
      { name: "name", label: "Name", type: "text", required: true },
      {
        name: "componentclassifierid",
        label: "Component Classifier ID",
        type: "number",
        required: true,
      },
      {
        name: "kakeibotrackerid",
        label: "Kakeibo Tracker ID",
        type: "number",
        required: true,
      },
    ],
  },
  kakeibotracker: {
    name: "Kakeibo Tracker",
    fields: [{ name: "name", label: "Name", type: "text", required: true }],
  },
  element: {
    name: "Element",
    fields: [
      { name: "name", label: "Name", type: "text", required: true },
      {
        name: "componentid",
        label: "Component ID",
        type: "number",
        required: true,
      },
    ],
  },
  recurringelement: {
    name: "Recurring Element",
    fields: [
      {
        name: "elementid",
        label: "Element ID",
        type: "number",
        required: true,
      },
      { name: "item", label: "Item", type: "text", required: true },
    ],
  },
  remindertype: {
    name: "Reminder Type",
    fields: [
      { name: "type", label: "Type", type: "text", required: true },
      { name: "name", label: "Name", type: "text", required: true },
    ],
  },
};
